<template>
  <div>
    <table v-if="cashflowColumns" class="cashflow-table mr-2 ml-2">
      <tr>
        <th colspan="100%" class="copy-header">
          <v-btn
            small
            text
            color="primary"
            class="float-right"
            @click="copyToExcel"
          >
            <v-icon left small>mdi-content-copy</v-icon>
            Copy to Excel
          </v-btn>
        </th>
      </tr>
      <tr>
        <th
          v-for="(col, i) in cashflowColumns"
          :key="i"
          :class="{
            rborder: true,
            'cashflow-table-header-cell': true,
            'sticky-left': i === 0,
            'first-col': i === 0,
            'sticky-top': true,
          }"
        >
          <v-tooltip v-if="col.tooltipText" top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on"> {{ col.text }}</span>
            </template>
            <span> {{ col.tooltipText }}</span>
          </v-tooltip>
          <span v-else class="header-span"> {{ col.text }} </span>
        </th>
      </tr>

      <tbody>
        <tr
          v-for="row in cashflowRows"
          :key="row.category"
          class="cashflow-table-value-row"
          @click="emitRowClick(row)"
        >
          <td
            v-for="(col, i) in cashflowColumns"
            :key="col.value"
            :class="{
              rborder: true,
              'deep-orange darken-3': shouldHighLight(row, col),
              'rounded-pill': shouldHighLight(row, col),

              'cashflow-table-value-cell': true,
              'sticky-left': i === 0,
            }"
          >
            <v-tooltip v-if="isToolTipNeeded(col.value, row[col.value])" top>
              <template v-slot:activator="{ on, attrs }">
                <p
                  :class="'font-weight-light'"
                  :style="'margin: 0px;'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span
                    :class="{
                      'value-span': true,
                      'font-weight-bold': shouldBoldify(row),
                      'red-text': shouldHighLight(row, col)
                        ? false
                        : shouldRedify(row, col),
                      'white--text': shouldHighLight(row, col),
                      'green-text': shouldGreenify(row, col),
                    }"
                  >
                    {{ row[col.value] }}
                  </span>
                </p>
              </template>
              <span>{{ cashflowFormulas[row[col.value]] }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="col.value == 'total/ha'" top>
              <template v-slot:activator="{ on, attrs }">
                <p
                  :class="'font-weight-light'"
                  :style="'margin: 0px;'"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span
                    :class="{
                      'value-span': true,
                      'font-weight-bold': shouldBoldify(row),
                      'red-text': shouldHighLight(row, col)
                        ? false
                        : shouldRedify(row, col),
                      'white--text': shouldHighLight(row, col),
                      'green-text': shouldGreenify(row, col),
                    }"
                  >
                    {{ row[col.value] }}
                  </span>
                </p>
              </template>
              <span>{{ totalHaToolTip }}</span>
            </v-tooltip>
            <span
              v-else
              :class="{
                'value-span': true,
                'font-weight-bold': shouldBoldify(row),
                'red-text': shouldHighLight(row, col)
                  ? false
                  : shouldRedify(row, col),
                'white--text': shouldHighLight(row, col),
                'green-text': shouldGreenify(row, col),
              }"
            >
              {{ parseRowValue(row[col.value]) }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="d-flex flex-row align-center my-2 justify-center">
      <span class="overline text--muted grey--text"
        >No fue posible mostrar la tabla</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bold {
  font-weight: 400;
  width: 100px;
}

table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

thead tr th {
  top: 0;
}

.cashflow-table {
  overflow-y: scroll;
  height: 40rem;
}

.rborder {
  width: 160px;
  border-right: 0px solid;
}

th {
  position: sticky;
}

.cashflow-table-value-cell {
  margin-left: 5px;
  margin-right: 3px;
}

td:empty {
  width: 300px;
}
.cashflow-table-header-cell {
  top: 0;
  width: 75px;
  font-size: 14px;
  text-align: left;

  margin: 10px;
  padding: 10px;
  font-weight: 300;
  border-bottom: 1px solid rgb(196, 196, 196);
}

.cashflow-table-value-cell {
  width: 100px;
  font-size: 14px;
  font-weight: 300;
  margin: 10px;
  padding: 10px;
}

.cashflow-table-value-row:hover {
  background-color: lightgreen;
  color: black;
  cursor: pointer;
}

.red-text {
  color: red;
}
.green-text {
  color: rgb(92, 223, 92);
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  opacity: 0.5;
  margin-top: 2px;
}

.sticky-left {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: rgb(241, 241, 241);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: rgb(241, 241, 241);
}

.first-col {
  z-index: 3;
}

.copy-header {
  background-color: rgb(241, 241, 241);
  border-bottom: none !important;
  padding: 4px 8px !important;
}

.float-right {
  float: right;
}
</style>

<script>
let accentCands = [
  {
    key: "category",
    value: "Ingresos",
  },
  {
    key: "category",
    value: "No Autofinanciables",
  },
  {
    key: "category",
    value: "Autofinanciables",
  },
  {
    key: "category",
    value: "Resultado Directo",
  },
  {
    key: "category",
    value: "Saldo Acumulado",
  },
  { key: "category", value: "Total Ingresos" },
  { key: "category", value: "Costos Totales" },
  {
    key: "category",
    value: "Ingreso Bruto Propio",
  },
  {
    key: "category",
    value: "Flujo de Fondos",
  },
  {
    key: "category",
    value: "Saldo",
  },
  // The next objects are for old cashflow compatibility
  // Will be removed in the future
  {
    key: "category",
    value: "Ganancias",
  },
  {
    key: "category",
    value: "Ganancias Acumuladas",
  },
  { key: "category", value: "Total ingresos" },
  {
    key: "category",
    value: "No autofinanciables",
  },
];

let strNums = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

import { cashflowFormulas } from "../../managrx/constants";
import { parseToLocaleString } from "../../managrx/utils";
export default {
  name: "DenseCashflowTable",
  props: {
    rows: Array,
    columns: Array,
    totalHaToolTip: String,
    endDate: String,
    startDate: String,
    isResourceUtilization: Boolean,
    name: {
      type: String,
      default: null
    }
  },
  computed: {
    cashflowStartDate() {
      // This avoid hours difference
      return new Date(new Date(this.startDate.split("T")[0]));
    },
    cashflowEndDate() {
      // This avoid hours difference
      return new Date(this.endDate);
    },
    cashflowRows() {
      if (this.endDate && this.startDate) {
        let newRows = [];

        for (const data of this.rows) {
          let newObj = {};
          newObj.total = data?.total;
          if (!this.isResourceUtilization) {
            newObj.category = data?.category;
            newObj["total/ha"] = data?.["total/ha"];
          } else {
            newObj.family = data?.family;
            newObj.item = data?.item;
          }
          let dataDateKeys = Object.keys(data).filter(
            (el) =>
              new Date(el) >= this.cashflowStartDate &&
              new Date(el) <= this.cashflowEndDate
          );
          for (const key of dataDateKeys) {
            newObj[key] = data?.[key];
          }
          newRows.push(newObj);
        }

        return newRows;
      } else {
        return this.rows;
      }
    },
    cashflowColumns() {
      if (this.endDate && this.startDate) {
        let constantColumns;
        if (!this.isResourceUtilization) {
          constantColumns = ["category", "total", "total/ha"];
        } else {
          constantColumns = ["item", "family", "total"];
        }
        let newColumns = this.columns?.filter(
          (el) =>
            constantColumns?.includes(el?.value) ||
            (new Date(el?.value) >= this.cashflowStartDate &&
              new Date(el?.value) <= this.cashflowEndDate)
        );
        return newColumns;
      } else {
        return this.columns;
      }
    },
    cashflowFormulasKeys() {
      return Object.keys(cashflowFormulas);
    },
    cashflowFormulas() {
      return cashflowFormulas;
    },
    minimunProfitValueLocaleString() {
      let profits = this.rows?.find((el) => el.category == "Saldo Acumulado");
      let profitsHistory = Object.assign({}, profits);
      delete profitsHistory?.["category"];
      delete profitsHistory?.["total"];
      delete profitsHistory?.["total/ha"];
      profitsHistory = Object.values(profitsHistory);
      profitsHistory = profitsHistory.map((el) =>
        parseFloat(el.replace(this.regexThousandsSeparator, ""))
      );
      let minValue = Math.min(...profitsHistory);
      minValue;
      return minValue.toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    regexDecimalsSeparator() {
      // Use .toLocaleString('en-US',{timeZone:'America/Argentina/Buenos_Aires'}) in a test value to get the locale decimals separator as regex exp
      let testNumber = (0.5).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
      let decimalSeparator = testNumber[1];
      return new RegExp(decimalSeparator, "g");
    },
    regexThousandsSeparator() {
      // Use .toLocaleString('en-US',{timeZone:'America/Argentina/Buenos_Aires'}) in a test value to get the locale thousands separator as regex exp
      let testNumber = (1000).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
      let thousandsSeparator = testNumber[1];
      return new RegExp(thousandsSeparator, "g");
    },
  },
  methods: {
    parseRowValue(value) {
      if (value === null) {
        return "-";
      }
      if (value === false) {
        return "No";
      }
      if (value === true) {
        return "Si";
      }
      if (typeof value === "string") {
        return value;
      } else {
        return parseToLocaleString(value);
      }
    },
    isToolTipNeeded(colName, rowValue) {
      if (
        colName == "category" &&
        this.cashflowFormulasKeys.includes(rowValue)
      ) {
        return true;
      }
      return false;
    },
    emitRowClick(row) {
      this.$emit("rowClick", row);
    },
    shouldBoldify(row) {
      for (let k = 0; k < accentCands.length; k++) {
        const candidate = accentCands[k];

        if (row[candidate.key] == candidate.value) {
          return true;
        }
      }

      return false;
    },

    shouldRedify(row, col) {
      try {
        if (row[col.value].startsWith("-")) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },

    shouldGreenify(row, col) {
      row;
      try {
        // row.category == "Ganancias Acumuladas" is only for project cashflow compatibility

        if (
          (row.category === "Saldo Acumulado" ||
            row.category == "Ganancias Acumuladas") &&
          strNums.some((v) => row[col.value].startsWith(v))
        ) {
          return true;
        }
        return false;
      } catch {
        return false;
      }
    },
    shouldHighLight(row, col) {
      try {
        // row.category == "Ganancias Acumuladas" is only for project cashflow compatibility

        if (
          (row.category === "Saldo Acumulado" ||
            row.category === "Ganancias Acumuladas") &&
          col.value != "total" &&
          col.value != "total/ha"
        ) {
          // If every value is 0, nothing should be highlighted
          let rowClone = { ...row };
          delete rowClone["category"];
          delete rowClone["total"];
          delete rowClone["total/ha"];

          let elementIsZero = (el) => el == 0;

          if (Object.values(rowClone).every(elementIsZero)) {
            return false;
          }
          if (this.isMinValue(row[col.value])) {
            return true;
          }
        }
        return false;
      } catch {
        return false;
      }
    },
    isMinValue(value) {
      return this.minimunProfitValueLocaleString == value;
    },
    copyToExcel() {
      // Get user's locale decimal separator
      const userLocale = navigator.language || 'en-US';
      const numberFormat = new Intl.NumberFormat(userLocale);
      const parts = numberFormat.formatToParts(1.1);
      const decimalSeparator = parts.find(part => part.type === 'decimal')?.value || '.';
      
      // Create header row
      const headers = this.cashflowColumns.map(col => col.text).join('\t');
      
      // Create data rows
      const rows = this.cashflowRows.map(row => {
        return this.cashflowColumns
          .map(col => {
            const value = row[col.value];
            
            // Skip non-numeric or empty values
            if (!value || typeof value !== 'string') {
              return value || '';
            }

            // Check if it's a number (including negative numbers and decimals)
            if (/^-?[\d,]+\.?\d*$/.test(value)) {
              // Remove thousands separators and convert to proper decimal format
              const cleanValue = value
                .replace(/,/g, '') // Remove all commas
                .replace(/\s/g, '') // Remove any whitespace
                .replace(/\./, decimalSeparator); // Replace decimal point with locale-specific separator
              
              return cleanValue;
            }

            return value;
          })
          .join('\t');
      });

      // Combine headers and rows
      const csvContent = [headers, ...rows].join('\n');

      // Copy to clipboard
      navigator.clipboard.writeText(csvContent)
        .then(() => {
          const notif = {
            success: true,
            data: {
              type: "success",
              text: this.name 
                ? `${this.name} copiado al portapapeles.`
                : "Cashflow copiado al portapapeles.",
            },
          };
          this.$store.dispatch("setNewNotification", notif);
        })
        .catch(err => {
          const notif = {
            success: false,
            data: {
              type: "error",
              text: this.name
                ? `No se pudo copiar ${this.name}.`
                : "No se pudo copiar el cashflow.",
            },
          };
          this.$store.dispatch("setNewNotification", notif);
          console.error('Failed to copy:', err);
        });
    },
  },
  data() {
    return {};
  },
};
</script>
